<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button></p>
            </div>
        </div>
        <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
            <el-tab-pane name="tabbasic">
                <span slot="label">基本信息</span>
                <div class="form-list">
                    <el-form :model="dataSource" ref="_projectForm" :rules="projectCheckRule">
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>加工单号：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ProcessOrderCode">
                                    <el-input type="text" :disabled="true" v-model="dataSource.ProcessOrderCode"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">仓库编码：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="WarehouseNo">
                                    <el-input v-if="!isDisabled" v-model="dataSource.WarehouseNo" readonly suffix-icon="el-icon-search" :maxlength="100" :minlength="2" placeholder="请选择仓库" @click.native="warehouseOptions.visible = true&&!isDisabled"></el-input>
                                    <el-input v-else v-model="dataSource.WarehouseNo" :disabled="true" :maxlength="100" :minlength="2"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">仓库名称：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="WarehouseName">
                                    <el-input type="text" v-model="dataSource.WarehouseName"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">下单时间：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ExpiryDate">
                                    <el-date-picker v-model="dataSource.OrderCreateTime" type="date" placeholder="下单时间" :picker-options="pickerOptions">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">计划加工时间：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PlanTime">
                                    <el-date-picker v-model="dataSource.PlanTime" type="date" placeholder="计划加工时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">加工单类型：</el-col>
                            <el-col :span="5">
                                <el-form-item prop="ServiceType">
                                    <el-select v-model.trim="dataSource.ServiceType" placeholder="请选择">
                                        <el-option v-for="item in serviceTypeLits" :key="item.BusinessCode"
                                                   :label="item.DictionaryName" :value="item.BusinessCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">备注：</el-col>
                            <el-col :span="17">
                                <el-form-item prop="Remark">
                                    <el-input type="textarea" v-model="dataSource.Remark" :maxlength="500"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-tabs type="border-card" v-model.trim="tab2activeName">
            <el-tab-pane label="加工原材料明细" name="tabMaterialitems">
                <div class="sl-border">
                    <el-table v-loading="loadding" :data="dataSource.StoreMaterialItem.Result" border
                              highlight-current-row>
                        <el-table-column type="index" width="50">
                        </el-table-column>
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column label="商品编码" min-width="120px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductNo">{{scope.row.ProductNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="外部编码" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.OuterID">{{scope.row.OuterID}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品名称" min-width="160px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductName">{{scope.row.ProductName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim.number="scope.row.Quantity">{{scope.row.Quantity}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="配比数量" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim.number="scope.row.RatioQty">{{scope.row.RatioQty}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="InventoryType" label="库存类型" min-width="130px">
                            <template slot-scope="scope">
                                <el-select v-model.trim="scope.row.InventoryType" size="mini" :maxlength="200">
                                    <el-option v-for="item in dataSource.InventoryTypes" :key="item.value"
                                               :label="item.label" :value="item.value" :disabled="!config.isCanProductEdit">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="生产日期" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductDate">{{scope.row.ProductDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="失效日期" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ExpireDate">{{scope.row.ExpireDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生产批次号" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductBatchNo">{{scope.row.ProductBatchNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="批次号" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.BatchCode">{{scope.row.BatchCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品条码" min-width="130px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductBarCode">{{scope.row.ProductBarCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
                            <template slot-scope="scope">
                                <span v-model.trim.number="scope.row.ProductUnit">{{scope.row.ProductUnit}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="规格" min-width="100px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.SKU">{{scope.row.SKU}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品毛重kg" min-width="120px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductGrossWeight">{{scope.row.ProductGrossWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品净重kg" min-width="120px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductNetWeight">{{scope.row.ProductNetWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品备注" min-width="150px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.Remark">{{scope.row.Remark}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="float: left;margin-top: 5px;">
                        商品共计：{{materialNum}}件
                    </div>
                    <div class="page">
                        <el-pagination v-if="!config.isCanEdit" @size-change="materialPageSizeChange"
                                       @current-change="materialCurrentChange" :current-page="dataSource.StoreMaterialItem.PageIndex"
                                       :page-sizes="[10, 30, 50, 100]" :page-size="dataSource.StoreMaterialItem.PageSize"
                                       layout="total, sizes, prev, pager, next, jumper"
                                       :total="dataSource.StoreMaterialItem.TotalCount">
                        </el-pagination>
                        <el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onMaterialRefresh">
                            刷新
                        </el-button>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="加工商品明细" name="tabProductitems">
                <div class="sl-border">
                    <el-table v-loading="loadding" :data="dataSource.StoreProductItem.Result" border
                               highlight-current-row>
                        <el-table-column type="index" width="50">
                        </el-table-column>
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column label="商品编码" min-width="120px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductNo">{{scope.row.ProductNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="外部编码" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.OuterID">{{scope.row.OuterID}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品名称" min-width="160px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductName">{{scope.row.ProductName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim.number="scope.row.Quantity">{{scope.row.Quantity}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="配比数量" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim.number="scope.row.RatioQty">{{scope.row.RatioQty}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="InventoryType" label="库存类型" min-width="130px">
                            <template slot-scope="scope">
                                <el-select v-model.trim="scope.row.InventoryType" size="mini" :maxlength="200">
                                    <el-option v-for="item in dataSource.InventoryTypes" :key="item.value"
                                               :label="item.label" :value="item.value" :disabled="!config.isCanProductEdit">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="生产日期" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductDate">{{scope.row.ProductDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="失效日期" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ExpireDate">{{scope.row.ExpireDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生产批次号" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductBatchNo">{{scope.row.ProductBatchNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="批次号" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.BatchCode">{{scope.row.BatchCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品条码" min-width="130px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductBarCode">{{scope.row.ProductBarCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
                            <template slot-scope="scope">
                                <span v-model.trim.number="scope.row.ProductUnit">{{scope.row.ProductUnit}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="规格" min-width="100px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.SKU">{{scope.row.SKU}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品毛重kg" min-width="120px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductGrossWeight">{{scope.row.ProductGrossWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品净重kg" min-width="120px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductNetWeight">{{scope.row.ProductNetWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品备注" min-width="150px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.Remark">{{scope.row.Remark}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="float: left;margin-top: 5px;">
                        商品共计：{{productNum}}件
                    </div>
                    <div class="page">
                        <el-pagination v-if="!config.isCanEdit" @size-change="productPageSizeChange"
                                       @current-change="productCurrentChange" :current-page="dataSource.StoreProductItem.PageIndex"
                                       :page-sizes="[10, 30, 50, 100]" :page-size="dataSource.StoreProductItem.PageSize"
                                       layout="total, sizes, prev, pager, next, jumper"
                                       :total="dataSource.StoreProductItem.TotalCount">
                        </el-pagination>
                        <el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onStoreProductItemRefresh">
                            刷新
                        </el-button>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <DialogEx :options="warehouseOptions" title="选择仓库" @onOk="onWarehouseOk">
            <Warehouse ref="warehouse" :config="warehouseConfig" @onSelectedRow="onWarehouseSelectedRow"></Warehouse>
        </DialogEx>
    </div>
</template>
<script>
    export default {
        data() {
            var _this = this;
            var checkWarehouseNo = function (rule, value, callback) {
                if (!value) return callback(new Error('请选择仓库'));
                callback();
            };
            return {
				loadding:false,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                activeName: 'tabbasic',
                tab2activeName: 'tabMaterialitems',
                isDisabled: false,
                projectCheckRule: {
                    WarehouseNo: [{ validator: checkWarehouseNo, trigger: 'blur' }],
                },
                //选择仓库
                warehouseConfig: {
                    isDetailDisplay: true,
                },
                warehouseOptions: {
                    visible: false,
                    size: 'small'
                },
                currentWarehouseSelectedRow: {},
                productNum: 0,
                materialNum: 0,
                serviceTypeLits:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            dataSource: {

            },
        },
        computed: {
            StoreProductList() {
                if (this.dataSource.StoreProductItem) {
                    return this.dataSource.StoreProductItem.Result;
                }
                return new Array();
            },
            StoreMaterialList() {
                if (this.dataSource.StoreMaterialItem) {
                    return this.dataSource.StoreMaterialItem.Result;
                }
                return new Array();
            }
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = this.Utils.emptyGuid != this.dataSource.StoreID;
                },
                deep: true
            },
            StoreProductList: {
                handler: function (nowVal, oldVal) {
                    this.productNum = 0;
                    if (nowVal) {
                        for (var i = 0; i < nowVal.length; i++) {
                            var product = nowVal[i];
                            if (product.Quantity != null && product.Quantity * 1 > 0) {
                                this.productNum += product.Quantity * 1;
                            }
                        }
                    }
                },
                deep: true
            },
            StoreMaterialList: {
                handler: function (nowVal, oldVal) {
                    this.materialNum = 0;
                    if (nowVal) {
                        for (var i = 0; i < nowVal.length; i++) {
                            var product = nowVal[i];
                            if (product.Quantity != null && product.Quantity * 1 > 0) {
                                this.materialNum += product.Quantity * 1;
                            }
                        }
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.Event.$on("clearEditProjectForm", () => this.resetForm);
           // this.getServiceTypeList();
        },
        methods: {
            //材料商品分页
            onMaterialRefresh() {
                this.materialSearchPage(this.dataSource.StoreMaterialItem);
            },
            materialPageSizeChange(val) {
                this.dataSource.StoreMaterialItem.PageSize = val;
                this.materialSearchPage(this.dataSource.StoreMaterialItem);
            },
            materialCurrentChange(val) {
                this.dataSource.StoreMaterialItem.PageIndex = val;
                this.materialSearchPage(this.dataSource.StoreMaterialItem);
            },
            materialSearchPage(search) {
                var pageSearch = {
                    PageIndex: search.PageIndex,
                    PageSize: search.PageSize,
                    IsNoPage: this.config.isCanEdit,
                    Params: {
                        ProcessOrderCode: this.dataSource.ProcessOrderCode
                    },
                };
                this.$ajax.query("omsapi/storeprocess/getstorematerialitemlist", "post", pageSearch, (result) => {
                    this.dataSource.StoreMaterialItem.Result = result.Result;
                    this.dataSource.StoreMaterialItem.TotalCount = result.TotalCount;
                });
            },
            //商品分页
            onProductRefresh() {
                this.productSearchPage(this.dataSource.StoreProductItem);
            },
            productPageSizeChange(val) {
                this.dataSource.StoreProductItem.PageSize = val;
                this.productSearchPage(this.dataSource.StoreProductItem);
            },
            productCurrentChange(val) {
                this.dataSource.StoreProductItem.PageIndex = val;
                this.productSearchPage(this.dataSource.StoreProductItem);
            },
            productSearchPage(search) {
                var pageSearch = {
                    PageIndex: search.PageIndex,
                    PageSize: search.PageSize,
                    IsNoPage: this.config.isCanEdit,
                    Params: {
                        ProcessOrderCode: this.dataSource.ProcessOrderCode
                    },
                };
                this.$ajax.query("omsapi/storeprocess/getstoreproductitemlist", "post", pageSearch, (result) => {
                    this.dataSource.StoreProductItem.Result = result.Result;
                    this.dataSource.StoreProductItem.TotalCount = result.TotalCount;
                });
            },
            //选择仓库
            onWarehouseSelectedRow: function (row) {
                this.currentWarehouseSelectedRow = row;
            },
            onWarehouseOk: function () {
                var _this = this;
                var row = this.currentWarehouseSelectedRow;
                if (row instanceof Object && row.WarehouseID != this.Utils.emptyGuid) {
                    this.dataSource.WarehouseNo = row.WarehouseNo;
                    this.dataSource.WarehouseName = row.WarehouseName;
                }
            },
            getServiceTypeList() {
                var _this = this;
                this.$ajax.query("omsapi/common/getsysdicchildrenbyparent", "get", { code: 730 }, data => {
                    if (data.IsSuccess == true) {
                        _this.serviceTypeLits = data.Result;
                    }
                });
            },
            addProject() {
                this.Event.$emit("onAddProject");
            },
            tab1Click: function (tab, event) {
            },
            tab2Click: function (tab, event) {
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_projectForm'].resetFields();//清空表单
            },
            saveProject() {
                var _this = this;
                _this.$refs["_projectForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        var routeName = _this.dataSource.WarehouseProjectID === _this.Utils.emptyGuid ? "creatstoreprocess" : "editstoreprocess";
                        _this.$ajax.send("omsapi/warehouseproject/" + storeprocess, "post", _this.dataSource, (data) => {
                            _this.dataSource.StoreID === _this.Utils.emptyGuid && (_this.dataSource.StoreID = data.Result);
                            _this.Event.$emit("reloadProjectList", data.Result);
                            _this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
			onStoreProductItemRefresh(){
				
				
			}
        },
        components: {
            //"Enterprise": resolve => { require(['@selector/enterpriseselector.vue.html'], resolve) },
            //"Warehouse": resolve => { require(['@selector/servicewarehouseselector.vue.html'], resolve) }
        }
    }
</script>